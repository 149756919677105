import { useQuery } from "@tanstack/react-query";
import { DocumentReference, Firestore, getDoc } from "firebase/firestore";
import { Product } from "@/types";
import useDatabase from "../database/useDatabase";
import useUsers from "../users/useUsers";

const fetchProducts = async (
  db: Firestore | null,
  fairRef: DocumentReference | null | undefined
) => {
  if (!db || !fairRef) {
    return [];
  }

  const fairDoc = await getDoc(fairRef);
  if (!fairDoc) {
    return [];
  }

  const fairData = fairDoc.data();
  if (!fairData) {
    return [];
  }

  return fairData.products as Product[];
};

const useProducts = () => {
  const { db } = useDatabase();
  const { currentUser } = useUsers();

  const fairRef = currentUser?.fairRef;
  const productsQuery = useQuery({
    queryKey: ["products", fairRef?.id],
    queryFn: async () => fetchProducts(db, fairRef),
  });

  const getProduct = (productId: string | undefined) => {
    if (!productId || !productsQuery?.data) {
      return null;
    }

    return productsQuery.data.find(
      (product: Product) => product.id === productId
    ) as Product;
  };

  return { productsQuery, getProduct };
};

export default useProducts;

import { useFirebase } from "@/hooks";
import { ReactNode, useMemo } from "react";
import { initializeFirestore, persistentLocalCache, getFirestore } from "firebase/firestore";
import DatabaseContext from "./DatabaseContext";

interface DatabaseProviderProps {
  children: ReactNode;
}

const DatabaseProvider = ({ children }: DatabaseProviderProps) => {
  const { app } = useFirebase();
  const db = useMemo(() => {
    if (!app) return null;

    try {
      return getFirestore(app);
    } catch (error) {
      return initializeFirestore(app, {
        localCache: persistentLocalCache({}),
      });
    }
  }, [app]);


  return (
    <DatabaseContext.Provider value={{ db }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseProvider;

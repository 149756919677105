import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useProducts, useOrders } from "@/hooks";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { OrderProduct } from "@/types";
import { formatPrice } from "@/lib/utils";

const formSchema = z.object({
  quantity: z.coerce.number().int().nonnegative(),
  discount: z.coerce.number().int().nonnegative().max(100),
});

const AddProductPage = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { getProduct } = useProducts();
  const { orderStore } = useOrders();
  const { toast } = useToast();

  const { addProduct } = orderStore;

  const product = getProduct(productId);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      quantity: 1,
      discount: 0,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (!product) {
      return;
    }

    const newOrderProduct = {
      name: product.name,
      price: product.price,
      total: product.price * values.quantity * (1 - values.discount / 100),
      ...values,
    } as OrderProduct;

    addProduct(newOrderProduct);
    toast({
      title: "Producto añadido exitosamente",
      description: `${product?.name}`,
    });
    navigate("/productos");
  };

  return (
    <>
      <h1>Agregar producto</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 pt-8">
          <FormItem>
            <FormLabel>Nombre</FormLabel>
            <FormControl>
              <p>{product?.name}</p>
            </FormControl>
          </FormItem>
          <FormItem>
            <FormLabel>Precio</FormLabel>
            <FormControl>
              <p>{formatPrice(product?.price)}</p>
            </FormControl>
          </FormItem>
          <FormField
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Cantidad</FormLabel>
                <FormControl>
                  <Input type="number" min="1" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="discount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Descuento (%)</FormLabel>
                <FormControl>
                  <Input type="number" min="0" max="100" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Añadir</Button>
        </form>
      </Form>
    </>
  );
};

export default AddProductPage;

import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { formatPrice } from "@/lib/utils";
import { useOrders } from "@/hooks";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";

const formSchema = z.object({
  quantity: z.coerce.number().int().nonnegative(),
  discount: z.coerce.number().int().nonnegative().max(100),
});

const getProductIndex = (orderProductIdString: string | undefined) => {
  if (orderProductIdString === undefined) {
    return null;
  }

  const orderProductIdNumber = Number(orderProductIdString);
  if (isNaN(orderProductIdNumber)) {
    return null;
  }

  return orderProductIdNumber - 1;
};

const EditOrderProductPage = () => {
  const navigate = useNavigate();
  const { orderProductId } = useParams();
  const { orderStore } = useOrders();
  const { toast } = useToast();

  const { products, updateProduct, deleteProduct } = orderStore;

  const productIndex = getProductIndex(orderProductId);
  const product = productIndex !== null ? products[productIndex] : null;
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      quantity: product?.quantity,
      discount: product?.discount,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (!product || productIndex === null) {
      return null;
    }

    const modifiedOrderProduct = {
      name: product.name,
      price: product.price,
      total: product.price * values.quantity * (1 - values.discount / 100),
      ...values,
    };

    updateProduct(modifiedOrderProduct, productIndex);
    toast({
      title: "Producto editado exitosamente",
      description: `${product.name}`,
    });
    navigate("/orden");
  };

  const onDelete = () => {
    if (productIndex === null) {
      return;
    }

    deleteProduct(productIndex);
    toast({
      title: "Product eliminado exitosamente",
      description: `${product?.name}`,
    });
    navigate("/orden");
  };

  return (
    <>
      <h1>Editar producto</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 pt-8">
          <FormItem>
            <FormLabel>Nombre</FormLabel>
            <FormControl>
              <p>{product?.name}</p>
            </FormControl>
          </FormItem>
          <FormItem>
            <FormLabel>Precio</FormLabel>
            <FormControl>
              <p>{formatPrice(product?.price)}</p>
            </FormControl>
          </FormItem>
          <FormField
            control={form.control}
            name="quantity"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Cantidad</FormLabel>
                <FormControl>
                  <Input type="number" min="1" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="discount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Descuento (%)</FormLabel>
                <FormControl>
                  <Input type="number" min="0" max="100" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center space-x-2">
            <Button type="submit">Editar</Button>
            <Button variant="destructive" onClick={() => onDelete()}>
              Eliminar
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default EditOrderProductPage;

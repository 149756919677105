import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import {
  FirebaseProvider,
  AuthProvider,
  DatabaseProvider,
  QueryClientProvider,
} from "@/context";
import App from "@/App";
import { initSentry } from "@/lib/sentry";

import "./index.css";

initSentry();

const providers = [
  FirebaseProvider,
  AuthProvider,
  DatabaseProvider,
  QueryClientProvider,
  BrowserRouter,
];

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

const ProviderComposer = providers.reduceRight(
  (children, Provider) => <Provider>{children}</Provider>,
  <App />
);

root.render(<React.StrictMode>{ProviderComposer}</React.StrictMode>);

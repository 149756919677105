import { DocumentReference } from "firebase/firestore";

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  fairRef: DocumentReference;
  isAdmin: boolean;
}

export interface Product {
  id: string;
  name: string;
  price: number;
}

export interface OrderProduct {
  name: string;
  price: number;
  quantity: number;
  discount: number;
  total: number;
}

export interface ElectronicBill {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
}

export interface Order {
  orderProducts: OrderProduct[];
  paymentMethod: string;
  discount: number;
  notes: string;
  timestamp: Date;
  userEmail: string;
  electronicBill: ElectronicBill | null;
}

export enum PaymentMethod {
  Cash = "Efectivo",
  Dataphone = "Datáfono",
  Sinpe = "SINPE",
  Other = "Otro",
  Dollars = "Dólares",
}

export interface Fair {
  name: string;
  ref: DocumentReference;
}

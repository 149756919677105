import { ReactNode, useEffect, useMemo, useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  User,
  setPersistence,
  browserSessionPersistence,
  signOut,
} from "firebase/auth";
import { useFirebase } from "@/hooks";
import {
  setUser as setSentryUser,
  clearUser as clearSentryUser,
} from "@/lib/sentry";

import AuthContext from "./AuthContext";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const { app } = useFirebase();
  const auth = useMemo(() => (app ? getAuth(app) : null), [app]);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!auth) return;

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);

      if (user?.email) {
        setSentryUser(user.email);
      }

      setIsLoading(false);
    });

    return unsubscribe;
  }, [auth]);

  const login = async (email: string, password: string) => {
    if (!auth) return;

    await setPersistence(auth, browserSessionPersistence);
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    if (!auth) return;

    await signOut(auth);
    clearSentryUser();
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

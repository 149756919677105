import * as React from "react";
import { format, isAfter, isBefore, addDays } from "date-fns";
import { DateRange } from "react-day-picker";
import { Calendar as CalendarIcon } from "lucide-react";

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  Row,
} from "@tanstack/react-table";

import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { Input } from "@/components/ui/input";
import { DataTablePagination } from "@/components/common/data-table-pagination";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  rowClickHandler: (row: Row<TData>) => void;
  // @ts-expect-error: implicit any
  tableRowsChangeHandler?: (rows) => void;
}

// @ts-expect-error: implicit any
const Filter = ({ column, table }) => {
  const [dateRange, setDateRange] = React.useState<DateRange | undefined>();
  const filter = column?.meta?.filter;

  if (filter?.type === "text") {
    return (
      <Input
        placeholder={filter?.placeholder}
        value={
          (table.getColumn(column?.accessorKey)?.getFilterValue() as string) ??
          ""
        }
        onChange={(event) =>
          table
            .getColumn(column?.accessorKey)
            ?.setFilterValue(event.target.value)
        }
        className="max-w-sm"
      />
    );
  }

  if (filter?.type === "date") {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !dateRange && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {format(dateRange.from, "LLL dd, y")} -{" "}
                  {format(dateRange.to, "LLL dd, y")}
                </>
              ) : (
                format(dateRange.from, "LLL dd, y")
              )
            ) : (
              <span>Escoja una fecha</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={(range) => {
              setDateRange(range);
              table.getColumn(column?.accessorKey)?.setFilterValue(range);
            }}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    );
  }

  return null;
};

// @ts-expect-error: implicit any
const getFilters = (columns, table) => {
  return (
    columns
      // @ts-expect-error: implicit any
      .filter((column) => column?.meta?.filter)
      // @ts-expect-error: implicit any
      .map((column) => (
        <Filter key={column?.accessorKey} column={column} table={table} />
      ))
  );
};

export function DataTable<TData, TValue>({
  columns,
  data,
  rowClickHandler,
  tableRowsChangeHandler,
}: DataTableProps<TData, TValue>) {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
    filterFns: {
      inDateRange: (row, columnId, filterValue) => {
        return filterValue?.from && filterValue?.to
          ? isAfter(row.original[columnId], filterValue.from) &&
              isBefore(row.original[columnId], addDays(filterValue.to, 1))
          : true;
      },
    },
  });
  const filters = getFilters(columns, table);
  const tableRows = table.getRowModel().rows;

  React.useEffect(() => {
    if (!tableRowsChangeHandler) {
      return;
    }

    tableRowsChangeHandler(tableRows);
  }, [tableRows, tableRowsChangeHandler]);

  return (
    <div>
      {!!filters?.length && (
        <div className="flex items-center py-4">{filters}</div>
      )}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {tableRows?.length ? (
              tableRows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  onClick={() => rowClickHandler(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No hay resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  );
}

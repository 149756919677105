import * as Sentry from "@sentry/react";

export function initSentry() {
  Sentry.init({
    dsn: "https://ff2bbc4db0f301e547c44cd17bc1f213@o4508389564022784.ingest.us.sentry.io/4508389565923328",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/feriana\.therainforestlab\.dev/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function setUser(email: string) {
  Sentry.setUser({ email });
}

export function clearUser() {
  Sentry.setUser(null);
}

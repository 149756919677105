import {
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
  NavLink,
  useNavigate,
} from "react-router-dom";
import { ChevronLeft, MoreVertical } from "lucide-react";

import { Button } from "@/components/ui/button";
import { Toaster } from "@/components/ui/toaster";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  LoginPage,
  ProductsPage,
  OrderPage,
  AddProductPage,
  EditProductOrderPage,
  ProfilePage,
  SalesPage,
  ViewSalePage,
} from "@/pages";
import { useAuth } from "@/hooks";

import "./App.css";

const RedirectRoute = () => {
  const { user } = useAuth();
  return user ? (
    <Navigate to="/productos" replace />
  ) : (
    <Navigate to="/login" replace />
  );
};

const Layout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const onProfileClickHandler = () => {
    navigate("/perfil");
  };

  const onLogoutClickHandler = async () => {
    await logout();
    navigate("/");
  };

  return (
    <>
      <div className="container">
        <div className="flex justify-between items-center">
          <nav className="flex items-center space-x-4 lg:space-x-4 py-4">
            <NavLink to="/productos" className="nav-link">
              Productos
            </NavLink>
            <NavLink to="/orden" className="nav-link">
              Orden
            </NavLink>
            <NavLink to="/ventas" className="nav-link">
              Ventas
            </NavLink>
          </nav>
          <div className="flex">
            <Button variant="ghost" size="icon">
              <ChevronLeft
                className="h-4 w-4"
                onClick={() => window.history.back()}
              />
            </Button>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreVertical className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Cuenta</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => onProfileClickHandler()}>
                  Perfil
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => onLogoutClickHandler()}>
                  Salir
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
        <main>
          <Outlet />
        </main>
      </div>
      <Toaster />
    </>
  );
};

const RequireAuth = () => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Layout />;
};

const App = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <p className="p-8">Cargando...</p>;
  }

  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<RequireAuth />}>
          <Route path="/productos" element={<ProductsPage />} />
          <Route path="/productos/:productId" element={<AddProductPage />} />
          <Route path="/orden" element={<OrderPage />} />
          <Route
            path="/orden/:orderProductId"
            element={<EditProductOrderPage />}
          />
          <Route path="/ventas" element={<SalesPage />} />
          <Route path="/ventas/:saleId" element={<ViewSalePage />} />
          <Route path="/perfil" element={<ProfilePage />} />
        </Route>
        <Route path="*" element={<RedirectRoute />} />
      </Routes>
    </>
  );
};

export default App;

import { useState } from "react";
import { useOrders, useUsers } from "@/hooks";
import { PaymentMethod, ElectronicBill } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { formatPrice } from "@/lib/utils";

const paymentMethodKeys = Object.keys(PaymentMethod) as [
  keyof typeof PaymentMethod
];

const formSchema = z.object({
  paymentMethod: z.enum(paymentMethodKeys),
  discount: z.coerce.number().int().nonnegative().max(100),
  notes: z.string(),
  electronicBill: z
    .object({
      id: z.string().optional(),
      name: z.string().optional(),
      address: z.string().optional(),
      email: z.string().optional(),
      phoneNumber: z.string().optional(),
    })
    .nullable(),
});

const OrderPage = () => {
  const navigate = useNavigate();
  const { orderStore, addOrderMutation } = useOrders();
  const { currentUser } = useUsers();
  const { toast } = useToast();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      paymentMethod: "Cash",
      discount: 0,
      notes: "",
      electronicBill: {
        id: "",
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
      },
    },
  });
  const [electronicBillChecked, setElectronicBillChecked] = useState(false);

  const discount = form.watch("discount");
  const { products, resetProducts } = orderStore;
  const userEmail = currentUser?.email;

  const rowClickHandler = (index: number) => {
    const orderProductId = index + 1;
    navigate(`/orden/${orderProductId}`);
  };

  const onElectronicBillCheckboxCheckedChange = (checked: boolean) => {
    form.resetField("electronicBill");
    setElectronicBillChecked(checked);
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (!userEmail || !products.length) {
      return;
    }

    const electronicBill =
      electronicBillChecked && values.electronicBill
        ? (values.electronicBill as ElectronicBill)
        : null;

    addOrderMutation.mutate({
      ...values,
      orderProducts: products,
      timestamp: new Date(),
      userEmail,
      electronicBill,
    });
    resetProducts();
    toast({ title: "Orden guardada correctamente" });
  };

  return (
    <div>
      <h1>Orden</h1>
      <div className="py-4">
        {products?.length ? (
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-8 pt-8"
            >
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Nombre</TableHead>
                    <TableHead>Cantidad</TableHead>
                    <TableHead>Venta</TableHead>
                    <TableHead>Unitario</TableHead>
                    <TableHead>Descuento</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {products.map(
                    ({ name, price, quantity, discount, total }, index) => (
                      <TableRow
                        key={index}
                        onClick={() => rowClickHandler(index)}
                      >
                        <TableCell>{name}</TableCell>
                        <TableCell>{quantity}</TableCell>
                        <TableCell>{formatPrice(total)}</TableCell>
                        <TableCell>{formatPrice(price)}</TableCell>
                        <TableCell>{discount}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>

              <FormField
                control={form.control}
                name="paymentMethod"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Método de pago</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Seleccione un método de pago" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.keys(PaymentMethod).map((key) => (
                          <SelectItem key={key} value={key}>
                            {PaymentMethod[key as keyof typeof PaymentMethod]}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="discount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Descuento total (%)</FormLabel>
                    <FormControl>
                      <Input type="number" min="0" max="100" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Notas</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex items-center space-x-2">
                <label
                  htmlFor="terms"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  Factura electrónica
                </label>
                <Checkbox
                  id="electronicBill"
                  checked={electronicBillChecked}
                  onCheckedChange={onElectronicBillCheckboxCheckedChange}
                />
              </div>

              {electronicBillChecked && (
                <>
                  <FormField
                    control={form.control}
                    name="electronicBill.id"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Cédula (física o jurídica)</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="electronicBill.name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Nombre (persona o sociedad)</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="electronicBill.phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Teléfono</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="electronicBill.address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Dirección</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="electronicBill.email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Correo electrónico</FormLabel>
                        <FormControl>
                          <Input type="text" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}

              <FormItem>
                <FormLabel>Total</FormLabel>
                <FormControl>
                  <p>
                    {formatPrice(
                      products
                        .map(({ total }) => total)
                        .reduce((acc, curr) => acc + curr) *
                        (1 - discount / 100)
                    )}
                  </p>
                </FormControl>
              </FormItem>

              <Button type="submit" className="mt-4">
                Efectuar compra definitiva
              </Button>
            </form>
          </Form>
        ) : (
          <p>No hay productos</p>
        )}
      </div>
    </div>
  );
};

export default OrderPage;

import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Firestore,
  collection,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import { Fair } from "@/types";
import useUsers from "../users/useUsers";
import useDatabase from "../database/useDatabase";

const fetchFairs = async (db: Firestore | null) => {
  if (!db) {
    return null;
  }

  const fairsRef = collection(db, "fairs");
  const querySnapshot = await getDocs(fairsRef);
  if (querySnapshot.empty) {
    return null;
  }

  const result: Fair[] = [];
  querySnapshot.forEach((doc) => {
    const docData = doc.data();

    result.push({
      name: docData.name,
      ref: doc.ref,
    } as Fair);
  });

  return result;
};

const useFairs = () => {
  const [currentFair, setCurrentFair] = useState<string | undefined>();
  const { db } = useDatabase();
  const { currentUser } = useUsers();
  const fairsQuery = useQuery({
    queryKey: ["fairs"],
    queryFn: async () => fetchFairs(db),
  });

  useEffect(() => {
    const getCurrentFair = async () => {
      if (!currentUser) {
        return null;
      }

      const fairDoc = await getDoc(currentUser.fairRef);
      if (!fairDoc) {
        return null;
      }

      const fairData = fairDoc.data();
      if (!fairData) {
        return null;
      }

      return fairData.name;
    };

    getCurrentFair().then((value) => setCurrentFair(value));
  }, [currentUser]);

  const updateCurrentFair = async (name: string) => {
    const fairs = fairsQuery?.data;
    if (!fairs) {
      return;
    }

    const newCurrentFair = fairs.find((fair) => fair.name === name);
    if (!newCurrentFair) {
      return;
    }

    if (!db) {
      return;
    }

    if (!currentUser) {
      return;
    }

    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(usersRef);
    if (querySnapshot.empty) {
      return null;
    }

    const currentUserRef = querySnapshot.docs.find(
      (doc) => doc.data().email === currentUser.email
    );
    if (!currentUserRef) {
      return;
    }

    await updateDoc(currentUserRef.ref, {
      fair: newCurrentFair.ref,
    });

    setCurrentFair(newCurrentFair?.name);
  };

  return { currentFair, fairs: fairsQuery?.data, updateCurrentFair };
};

export default useFairs;

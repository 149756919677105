import { useNavigate } from "react-router-dom";
import { ColumnDef, Row } from "@tanstack/react-table";

import { useProducts } from "@/hooks";
import { DataTable } from "@/components/common/data-table";
import { formatPrice } from "@/lib/utils";
import { Product } from "@/types";

const columns: ColumnDef<Product>[] = [
  {
    accessorKey: "name",
    header: "Nombre",
    meta: {
      filter: {
        type: "text",
        placeholder: "Buscar por nombre",
      },
    },
  },
  {
    accessorKey: "price",
    header: "Precio",
    cell: ({ row }) => {
      const price = parseFloat(row.getValue("price"));
      const formatted = formatPrice(price);

      return <div className="text-right font-medium">{formatted}</div>;
    },
  },
];

const ProductsPage = () => {
  const { productsQuery } = useProducts();
  const navigate = useNavigate();

  const { data: products, isPending, error } = productsQuery;
  const rowClickHandler = (row: Row<Product>) => {
    navigate(`/productos/${row.original.id}`);
  };

  return (
    <>
      <h1>Productos</h1>
      <div className="py-4">
        {isPending ? (
          <p>Cargando productos...</p>
        ) : error ? (
          <p>Error al cargar los productos: {error.message}</p>
        ) : (
          <DataTable
            columns={columns}
            data={products}
            rowClickHandler={rowClickHandler}
          ></DataTable>
        )}
      </div>
    </>
  );
};

export default ProductsPage;

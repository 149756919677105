import { createContext } from "react";
import { FirebaseApp } from "firebase/app";

interface FirebaseContextType {
  app: FirebaseApp | null;
}

const FirebaseContext = createContext<FirebaseContextType>({ app: null });

export default FirebaseContext;

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";
import { useOrders, useUsers } from "@/hooks";
import { DataTable } from "@/components/common/data-table";
import { PaymentMethod } from "@/types";
import { formatPrice } from "@/lib/utils";

const columns = [
  {
    accessorKey: "fullName",
    header: "Usuario",
  },
  {
    accessorKey: "date",
    header: "Fecha y hora",
    // @ts-expect-error: implicit any
    cell: ({ row }) => {
      const date = row.getValue("date") as Date;
      const formatted = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;

      return <div className="text-right font-medium">{formatted}</div>;
    },
    filterFn: "inDateRange",
    meta: {
      filter: {
        type: "date",
      },
    },
  },
  {
    accessorKey: "paymentMethod",
    header: "Método de pago",
    // @ts-expect-error: implicit any
    cell: ({ row }) => {
      const paymentMethod = row.getValue("paymentMethod");
      const formatted =
        PaymentMethod[paymentMethod as keyof typeof PaymentMethod];

      return <div className="text-right font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "products",
    header: "Cantidad de productos",
  },
  {
    accessorKey: "discount",
    header: "Descuento",
  },
  {
    accessorKey: "total",
    header: "Total",
    // @ts-expect-error: implicit any
    cell: ({ row }) => {
      const total = parseFloat(row.getValue("total"));
      const formatted = formatPrice(total);

      return <div className="text-right font-medium">{formatted}</div>;
    },
  },
];

const SalesPage = () => {
  const navigate = useNavigate();
  const { ordersQuery } = useOrders();
  const { findUser } = useUsers();
  const [total, setTotal] = useState(0);

  const getOrderDate = (timestamp: Timestamp) =>
    new Date(timestamp.seconds * 1000);
  const getUserFullName = (email: string) => {
    const user = findUser(email);

    return `${user?.firstName} ${user?.lastName}`;
  };
  const orders = ordersQuery?.data
    ? ordersQuery.data
        .map((order, index) => ({
          id: index + 1,
          date: getOrderDate(order.timestamp as unknown as Timestamp),
          products: order.orderProducts.length,
          total:
            order.orderProducts.reduce(
              (acc, curr) =>
                acc + curr.price * curr.quantity * (1 - curr.discount / 100),
              0
            ) *
            (1 - order.discount / 100),
          fullName: getUserFullName(order.userEmail),
          ...order,
        }))
        .sort((a, b) => b.date.getTime() - a.date.getTime())
    : [];

  // @ts-expect-error: implicit any
  const rowClickHandler = (row) => {
    navigate(`/ventas/${row.original.id}`);
  };

  // @ts-expect-error: implicit any
  const tableRowsChangeHandler = (rows) => {
    // @ts-expect-error: implicit any
    const total = rows.reduce((acc, curr) => acc + curr.getValue("total"), 0);
    setTotal(total);
  };

  return (
    <div className="pb-4">
      <h1>Ventas</h1>
      <p className="pt-2">
        Total período: <strong>₡{formatPrice(total)}</strong>
      </p>

      {orders && (
        <DataTable
          data={orders}
          // @ts-expect-error: implicit any
          columns={columns}
          rowClickHandler={rowClickHandler}
          tableRowsChangeHandler={tableRowsChangeHandler}
        />
      )}
    </div>
  );
};

export default SalesPage;

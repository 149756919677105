import { ReactNode } from "react";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "@/config";
import FirebaseContext from "./FirebaseContext";

interface FirebaseProviderProps {
  children: ReactNode;
}

const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const app = initializeApp(firebaseConfig);

  return (
    <FirebaseContext.Provider value={{ app }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;

import { useUsers, useFairs } from "@/hooks";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const ProfilePage = () => {
  const { currentUser } = useUsers();
  const { currentFair, fairs, updateCurrentFair } = useFairs();

  const onFairChange = async (name: string) => {
    await updateCurrentFair(name);
  };

  return (
    <>
      <h1>Perfil</h1>
      <div className="py-2">
        <Label>Usuario</Label>
        <p>
          {currentUser?.firstName} {currentUser?.lastName}
        </p>
      </div>
      <div className="py-2">
        <Label>Correo electrónico</Label>
        <p>{currentUser?.email}</p>
      </div>
      <div className="py-2">
        <Label>Feria</Label>
        {currentUser?.isAdmin && (
          <div className="py-2">
            <Select onValueChange={onFairChange} value={currentFair}>
              <SelectTrigger>
                <SelectValue placeholder="Seleccione una feria" />
              </SelectTrigger>
              <SelectContent>
                {fairs &&
                  fairs.map(({ name }, i) => (
                    <SelectItem key={i} value={name}>
                      {name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {!currentUser?.isAdmin && <p>{currentFair}</p>}
      </div>
    </>
  );
};

export default ProfilePage;

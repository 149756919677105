import { useParams } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

import { PaymentMethod, Order } from "@/types";
import { useOrders, useUsers } from "@/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { formatPrice } from "@/lib/utils";

const ViewSalePage = () => {
  const { saleId } = useParams();
  const { findOrder } = useOrders();
  const { findUser } = useUsers();

  const order = findOrder(saleId ? parseInt(saleId) - 1 : null);
  if (!order) {
    return null;
  }

  const user = findUser(order?.userEmail);

  const getOrderDateTime = (timestamp: Timestamp) => {
    const d = new Date(timestamp.seconds * 1000);

    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  };
  const getOrderTotal = (order: Order) =>
    order.orderProducts.reduce((acc, curr) => acc + curr.total, 0) *
    (1 - order.discount / 100);

  return (
    <div className="pb-4">
      <h1>Venta</h1>
      <div className="py-2">
        <Label>Usuario</Label>
        <p>
          {user?.firstName} {user?.lastName}
        </p>
      </div>
      <div className="py-2">
        <Label>Fecha y hora</Label>
        <p>{getOrderDateTime(order.timestamp as unknown as Timestamp)}</p>
      </div>
      <div className="py-2">
        <Label>Método de pago</Label>
        <p>
          {PaymentMethod[order?.paymentMethod as keyof typeof PaymentMethod]}
        </p>
      </div>
      <div className="py-2">
        <Label>Descuento total</Label>
        <p>{order?.discount}</p>
      </div>
      <div className="py-2">
        <Label>Total</Label>
        <p>{formatPrice(getOrderTotal(order))}</p>
      </div>
      {order?.electronicBill && (
        <>
          <div className="pt-4">
            <p>Factura electrónica</p>
          </div>

          <div className="py-2">
            <Label>Cédula</Label>
            <p>{order.electronicBill.id}</p>
          </div>
          <div className="py-2">
            <Label>Nombre</Label>
            <p>{order.electronicBill.name}</p>
          </div>
          <div className="py-2">
            <Label>Correo electrónico</Label>
            <p>{order.electronicBill.email}</p>
          </div>
          <div className="py-2">
            <Label>Teléfono</Label>
            <p>{order.electronicBill.phoneNumber}</p>
          </div>
          <div className="py-2">
            <Label>Dirección</Label>
            <p>{order.electronicBill.address}</p>
          </div>
        </>
      )}

      <div className="py-2">
        <Label>Notas</Label>
        <Textarea disabled value={order?.notes} />
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Nombre</TableHead>
            <TableHead>Cantidad</TableHead>
            <TableHead>Venta</TableHead>
            <TableHead>Unitario</TableHead>
            <TableHead>Descuento</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {order?.orderProducts.map(
            ({ name, price, quantity, discount, total }, i) => (
              <TableRow key={i}>
                <TableCell>{name}</TableCell>
                <TableCell>{quantity}</TableCell>
                <TableCell>{formatPrice(total)}</TableCell>
                <TableCell>{formatPrice(price)}</TableCell>
                <TableCell>{discount}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default ViewSalePage;

import { Firestore } from "firebase/firestore";
import { createContext } from "react";

interface DatabaseContextType {
  db: Firestore | null;
}

const DatabaseContext = createContext<DatabaseContextType>({ db: null });

export default DatabaseContext;

import { useQuery } from "@tanstack/react-query";
import { Firestore, collection, getDocs } from "firebase/firestore";
import { User } from "@/types";

import useAuth from "../auth/useAuth";
import useDatabase from "../database/useDatabase";

const fetchUsers = async (db: Firestore | null) => {
  if (!db) {
    return null;
  }

  const usersRef = collection(db, "users");
  const querySnapshot = await getDocs(usersRef);
  if (querySnapshot.empty) {
    return null;
  }

  const result: User[] = [];
  querySnapshot.forEach((doc) => {
    const docData = doc.data();

    result.push({
      email: docData.email,
      firstName: docData.firstName,
      lastName: docData.lastName,
      fairRef: docData.fair,
      isAdmin: !!docData?.isAdmin,
    } as User);
  });

  return result;
};

const useUsers = () => {
  const { user } = useAuth();
  const { db } = useDatabase();
  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => fetchUsers(db),
  });

  const findUser = (email: string | null | undefined) => {
    const users = usersQuery?.data;
    if (!users || !email) {
      return null;
    }

    return users.find((user) => user.email === email);
  };
  const currentUser = findUser(user?.email);

  return { currentUser, findUser };
};

export default useUsers;

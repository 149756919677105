import { create } from "zustand";
import { useMutation } from "@tanstack/react-query";
import { persist, createJSONStorage } from "zustand/middleware";
import { OrderProduct, Order } from "@/types";
import { useQuery } from "@tanstack/react-query";
import {
  Firestore,
  DocumentReference,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import useDatabase from "../database/useDatabase";
import useUsers from "../users/useUsers";

interface OrderStore {
  products: OrderProduct[];
  addProduct: (product: OrderProduct) => void;
  updateProduct: (product: OrderProduct, index: number) => void;
  deleteProduct: (index: number) => void;
  resetProducts: () => void;
}

const useOrderStore = create<OrderStore>()(
  persist(
    (set) => ({
      products: [],
      addProduct: (product) => {
        set((state) => ({ products: state.products.concat([product]) }));
      },
      updateProduct: (product, index) => {
        set((state) => ({
          products: [
            ...state.products.slice(0, index),
            product,
            ...state.products.slice(index + 1),
          ],
        }));
      },
      deleteProduct: (index) => {
        set((state) => ({
          products: [
            ...state.products.slice(0, index),
            ...state.products.slice(index + 1),
          ],
        }));
      },
      resetProducts: () => {
        set(() => ({
          products: [],
        }));
      },
    }),
    {
      name: "feriana-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

const addOrder = async (
  db: Firestore | null,
  fairRef: DocumentReference | null | undefined,
  newOrder: Order
) => {
  if (!db || !fairRef) {
    return null;
  }

  await updateDoc(fairRef, {
    orders: arrayUnion(newOrder),
  });
};

const fetchOrders = async (
  db: Firestore | null,
  fairRef: DocumentReference | null | undefined
) => {
  if (!db || !fairRef) {
    return [];
  }

  const fairDoc = await getDoc(fairRef);
  if (!fairDoc) {
    return [];
  }

  const fairData = fairDoc.data();
  if (!fairData) {
    return [];
  }

  return fairData.orders as Order[];
};

const useOrders = () => {
  const orderStore = useOrderStore();
  const { db } = useDatabase();
  const { currentUser } = useUsers();

  const fairRef = currentUser?.fairRef;
  const addOrderMutation = useMutation({
    mutationFn: (newOrder: Order) => addOrder(db, fairRef, newOrder),
  });
  const ordersQuery = useQuery({
    queryKey: ["orders", fairRef?.id],
    queryFn: async () => fetchOrders(db, fairRef),
  });

  const findOrder = (orderId: number | undefined | null) => {
    const orders = ordersQuery?.data;
    if (!orders || orderId === null || orderId === undefined) {
      return null;
    }

    return orders[orderId];
  };

  return { orderStore, addOrderMutation, ordersQuery, findOrder };
};

export default useOrders;

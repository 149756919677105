import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatPrice(price: number | undefined) {
  if (price === undefined) {
    return null;
  }

  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  const formatter = new Intl.NumberFormat("es-CR", options);
  return formatter.format(price);
}
